import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import { useState } from 'react';
import axios from 'axios';


const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
        // Add more fields as needed
    });

    const { name, email, message } = formData; // Destructure the fields from formData

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://backend-t9vg.onrender.com/sendEmail', formData);
            // const response = await axios.post('http://127.0.0.1:5002/sendEmail', formData);

            console.log(response.data); // Optional: Log the response

            // Display a success alert
            alert('Yay! Your message has been sent!');
        } catch (error) {
            console.error(error);
            // Display an error alert if the API request fails
            alert('Error submitting form. Please try again or ping us directly at info@yicokw.com');
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="col-12 col-sm-6 mb-7">
                <input
                    type="text"
                    name="name"
                    value={name}

                    className="form-control"
                    id="name"

                    placeholder="Your Name*"
                    onChange={handleChange}
                />
            </div>
            <div className="col-12 col-sm-6 mb-7">
                <input
                    type="text"
                    name="email"
                    value={email}

                    className="form-control"
                    id="email"

                    placeholder="Your Email*"
                    onChange={handleChange}
                />
            </div>
            {/* <div className="col-12 col-sm-6 mb-7">
                <input
                    type="text"
                    name="message"
                    value={message}

                    className="form-control"
                    id="message"
                    cols="30"
                    rows="10"

                    placeholder="Your message*"
                    onChange={handleChange}
                />
            </div> */}
            <div className="col-12 mb-9">
                <textarea
                    className="form-control massage-control"
                    name="message"
                    id="massage"
                    cols="30"
                    rows="10"
                    placeholder="Message"
                    value={message}
                    onChange={handleChange}
                // ref={register({
                //     required: "Message is required",
                // })}
                ></textarea>
                {/* {errors.message && <p>{errors.message.message}</p>} */}
            </div>

            {/* Add more input fields for additional form fields */}

            {/* <button type="submit">Submit</button> */}
            <div className="col-12">
                <button
                    id="contactSubmit"
                    type="submit"
                    className="btn btn-dark btn-hover-dark"
                    data-complete-text="Well Done!"
                >
                    Send Message
                </button>
            </div>
        </form>
    );
};


// const ContactForm = () => {
//     const { register, errors } = useForm({
//         mode: "onBlur",
//     });
//     return (
//         <Fragment>
//             <form
//                 id="contactForm"
//                 className="row"
//                 action="http://yicokw.com:5555/sendEmail"
//                 method="POST"
//             >
//                 <div className="col-12 col-sm-6 mb-7">
//                     <input
//                         type="text"
//                         className="form-control"
//                         id="name"
//                         name="name"
//                         placeholder="Your Name*"
//                         ref={register({ required: "Name is required" })}
//                     />
//                     {errors.name && <p>{errors.name.message}</p>}
//                 </div>
//                 <div className="col-12 col-sm-6 mb-7">
//                     <input
//                         type="text"
//                         className="form-control"
//                         id="email"
//                         name="email"
//                         placeholder="Your Email*"
//                         ref={register({
//                             required: "Email is required",
//                             pattern: {
//                                 value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
//                                 message: "invalid email address",
//                             },
//                         })}
//                     />
//                     {errors.email && <p>{errors.email.message}</p>}
//                 </div>

//                 <div className="col-12 mb-9">
//                     <textarea
//                         className="form-control massage-control"
//                         name="message"
//                         id="massage"
//                         cols="30"
//                         rows="10"
//                         placeholder="Message"
//                         ref={register({
//                             required: "Message is required",
//                         })}
//                     ></textarea>
//                     {errors.message && <p>{errors.message.message}</p>}
//                 </div>
//                 <div className="col-12">
//                     <button
//                         id="contactSubmit"
//                         type="submit"
//                         className="btn btn-dark btn-hover-dark"
//                         data-complete-text="Well Done!"
//                     >
//                         Send Message
//                     </button>
//                 </div>
//             </form>
//         </Fragment>
//     );
// };

const CallEmailApi = (userEmail, userMsg, userName) => {



    //   const userEmail = reqBody.email;
    //   const userMsg = reqBody.message;
    //   const userName = reqBody.name;

    const toEmail = ["info@yicokw.com", "emadk3@gmail.com", "shakir1166@yahoo.com"];

    if (!toEmail) {
        console.log("Failed to get email from redis");
        const statusCode = 205;
        const msgCode = "ARSY-9001";
        return "failed";
    }

    console.log(`to email is ===> ${toEmail}`);
    const subject = `enquiry fromn ${userName} and ${userEmail}`;
    console.log(`subject ===> ${subject}`);
    const htmlContent = `<strong> FROM Name: ${userName}.<br><br> From Email:  ${userEmail}. <br><br> Phone Number: ${""}.<br><br> Subject ${subject}<br><br> Message:${userMsg}</strong>`;
    console.log(`htmlContent ===> ${htmlContent}`);

    if (!subject) {
        console.log("failed to get subject from db.notificstion_template table");
        return;
    }

    const message = {
        from: fromEmail,
        to: toEmail,
        subject: subject,
        html: htmlContent
    };

    sgMail.setApiKey(sendgridConfig.token);

    sgMail
        .send(message)
        .then((response) => {
            console.log(`Response From Send Grid: ${JSON.stringify(response)}`);
            console.log(response[0].statusCode);
            console.log(typeof response[0].statusCode);

            if (response[0].statusCode === 202) {
                const statusCode = 200;
                const msgCode = null;
                return "Yay! your message has been sent"
            }

            const statusCode = 205;
            const msgCode = "ARGM-80005";
            return "failed, please try reaching us at info@yicokw.com"
        })
        .catch((error) => {
            console.error(`error sending mail: ${error}`);
            const statusCode = 201;
            const msgCode = "ARGM-80130";
            return "System issue, Could not send message"
        });

}

export default ContactForm;
